<template>
  <Header :sticky="true" :breadcrumbItems="breadcrumbItems" />
  <template v-if="product">
    <ProductAndFormWrapper class="container" />
  </template>
  <Footer />
</template>

<script>
import { computed, watchEffect} from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Header from '@/components/Header.vue';
import ProductAndFormWrapper from '@/components/ProductAndFormWrapper.vue';
import Footer from '@/components/Footer.vue';


export default {
  components: {
    Header,
    ProductAndFormWrapper,
    Footer
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const product = computed(() => store.getters.productDetails);
    watchEffect(() => {
      if (!product.value.id) {
        router.push({ name: 'home' });
      }
    });
    
    return {
      product
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        { text: this.$t('homePageLabel'), href: '/' },
        { text: this.$t('missingDocumentPageLabel'), href: null, active: true }
      ]
    }
  }
};
</script>


<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  background-color: #0d2c44;
  max-width: 100%;
  overflow-x: hidden;
  flex-grow: 1;
}
</style>
