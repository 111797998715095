<template>
  <div class="product-form-wrapper">
    <div v-if="!showSuccessMessage">
      <ProductDetailsCard />
      <MissingDocumentForm @form-submitted="handleFormSubmitted" />
    </div>

    <div v-else class="success-message">
      <MNotification type="success" class="notification">
        <p>{{ $t('successfullFormSubmitionNotificationMessage') }}</p>
      </MNotification>
      <MButton :label="$t('backToHomePage')" @click="navigateHome" id="returnHome" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ProductDetailsCard from '@/components/ProductDetailsCard.vue';
import MissingDocumentForm from '@/components/MissingDocumentForm.vue';
import MNotification from '@mozaic-ds/vue-3/src/components/notification/MNotification.vue';
import MButton from '@mozaic-ds/vue-3/src/components/button/MButton.vue';
import { useRouter } from 'vue-router';

const showSuccessMessage = ref(false);
const router = useRouter();

const handleFormSubmitted = () => {
  showSuccessMessage.value = true;
  scrollToTop()
};

const navigateHome = () => {
  router.push({ name: 'home' });
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

onMounted(() => {
    scrollToTop();

});
</script>

<style scoped>
.product-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: 1008px;
  margin: 0 auto;
}

.success-message {
  text-align: center;
  margin-top: 20px;
}
.notification {
    margin-bottom: 20px;
}
</style>
