export default class PhotoService {
  getImagePath(photoUrl) {
    if (photoUrl === '') {
      return require('../assets/noPhotoAvailable.png');
    }
    return photoUrl + '?width=165';
  }

	getLogoImagePath(name) {
		return require('../assets/logos/brandLogos/' + name + '.png');
	}

	getFlagImagePath(name) {
		return require('../assets/flags/' + name + '.svg');
	}
}
