import { createStore } from 'vuex';
import { ProductDetailsService } from '@/service/ProductDetailsService';
import { PRODUCT_PER_PAGE } from '@/constants/product';
import { getExtension, getTranslatedKeyForDocument } from '@/utils/files';
import { t } from 'i18next';
import { getImagePath } from '@/utils/image';

export default createStore({
  state: {
    keywords: '',
    searchLoading: false,
    products: {},
    selectedProduct: {
      openLayer: false,
      details: {},
    },
  },
  mutations: {
    getProducts(state, products) {
      state.products = products;
    },
    setSearchKeyword(state, keyWord) {
      state.keyWord = keyWord;
    },
    setSearchLoading(state, value) {
      state.searchLoading = value;
    },
    openCloseLayerProductDetails(state, value) {
      state.selectedProduct.openLayer = value;
    },
    setProductDetails(state, product) {
      state.selectedProduct.details = {
          ...product,
          photoUrl: product.photoUrl || ''
      };    },
  },
  actions: {
    async searchProducts(context, payload) {
      try {
        const { keyWord, from = 1 } = payload;
        context.commit('setSearchLoading', true);
        context.commit('setSearchKeyword', keyWord);
        const response = await ProductDetailsService.getProductsPageWithPaginationParams(
          keyWord,
          from,
          PRODUCT_PER_PAGE,
        );
        const products = await response.json();
        context.commit('getProducts', products);
        context.commit('setSearchLoading', false);
      } catch (error) {
        throw new Error(error);
      }
    },
    displayLayerProductDetails(context, value) {
      context.commit('openCloseLayerProductDetails', value);
    },
    async getProductDetails(context, product) {
      try {
      const productDetailsInfos = { ...product, files: [] };

      const response = await ProductDetailsService.getProductDetails(
          product.adeoKey,
          product.gtins,
      );
      const productDetails = await response.json();
      if (!product.photoUrl) {
        await ProductDetailsService.getProductPhotoUrl(product.adeoKey, product.buCode)
            .then((response) => response.text())
            .then((text) => {
                productDetailsInfos.photoUrl = `${getImagePath(text)}?width=500`;
            });
       }
        if (productDetails.files && productDetails.files.length) {
          const files = productDetails.files;

          productDetailsInfos.files = files.map((file, index) => {
            const translationKey = getTranslatedKeyForDocument(file.documentType);
            const translatedDocumentType = t(translationKey); 
            const buildFileName = `${translatedDocumentType}-${product.gtins}-${product.adeoKey}-${index+1}`;
            return {
              ...file,
              fileExtension: getExtension(file.fileName),
              documentTypeLabel: translatedDocumentType,
              fileNameToDisplay: buildFileName,
            };
          });
        context.commit('setProductDetails', productDetailsInfos);
            } else {
                context.commit('setProductDetails', productDetailsInfos);
            }
        } catch (error) {
            throw new Error(error);
        }
    },
},
  getters: {
    products: (state) => state.products,
    searchKeyWord: (state) => state.keyWord,
    searchLoading: (state) => state.searchLoading,
    isOpenLayer: (state) => state.selectedProduct.openLayer,
    productDetails: (state) => state.selectedProduct.details,
    productDetailsFiles: (state) => state.selectedProduct.details.files,
  },
});