import { createRouter, createWebHistory } from 'vue-router';
import AboutView from '../views/AboutView.vue';
import NotFound from '../views/NotFound.vue';
import MissingDocumentView from '../views/MissingDocumentView.vue';
import LegalNoticesView from "@/views/LegalNoticesView.vue";
import DataPrivacyView from "@/views/DataPrivacyView.vue";

const routes = [
	{ path: '/', name: 'home', component: AboutView },
	{ path: '/ac/', redirect: '/' },
	{ path: '/fans/', redirect: '/' },
	{ path: '/404', name: 'NotFound', component: NotFound },
	{ path: '/:catchAll(.*)', redirect: '404' },
	{
		path: '/missing-document',
		name: 'missing',
		component: MissingDocumentView,
	},{
		path: '/legal-notices',
		name: 'notices',
		component: LegalNoticesView,
	},{
		path: '/data-privacy',
		name: 'privacy',
		component: DataPrivacyView,
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

export default router;
