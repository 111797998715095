<script setup>
	import { MField, MSelect } from '@mozaic-ds/vue-3';
	import i18next from 'i18next';
	import { LANGUAGES } from '@/constants/header';
	import { ref, onMounted, watchEffect } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';

	let defaultValue = ref('');
	const route = useRoute();

	const setInitialLanguage = async () => {
		await i18next.init(); 
		const currentLanguageCode = i18next.language || 'en';
		const foundLanguage = Object.values(LANGUAGES).find(lang => lang.code === currentLanguageCode);
		if (foundLanguage) {
			defaultValue.value = foundLanguage.code;
		} else {
			defaultValue.value = 'en'; 
		}
	};

	onMounted(setInitialLanguage);

	watchEffect(() => {
		const currentLanguageCode = i18next.language;
		const foundLanguage = Object.values(LANGUAGES).find(lang => lang.code === currentLanguageCode);
		if (foundLanguage && defaultValue.value !== foundLanguage.code) {
			defaultValue.value = foundLanguage.code;
		}
	});

	const changeLanguage = (code) => {
	const selectedLanguage = Object.values(LANGUAGES).find(lang => lang.code === code);
	if (selectedLanguage) {
		i18next.changeLanguage(selectedLanguage.code).then(() => {
		defaultValue.value = selectedLanguage.code;
		if (route.name === 'home') {
			store.dispatch('searchProducts', { keyWord: store.getters.searchKeyWord });
		}
		});
	}
	};
</script>

<template>
  <MField id="languageSelector" label="" class="l-language-selector">
    <MSelect
      v-bind="{
        options: Object.values(LANGUAGES).map(lang => ({ value: lang.code, text: lang.text })),
        id: 'languageSelector',
        size: 's',
      }"
      @update:model-value="changeLanguage"
      v-model="defaultValue"
    />
  </MField>
  <MField id="languageSelector" label="" class="s-language-selector">
    <MSelect
      v-bind="{
        options: Object.values(LANGUAGES).map(lang => ({ value: lang.code, text: lang.shortText })),
        id: 'languageSelector',
        size: 's',
      }"
      @update:model-value="changeLanguage"
      v-model="defaultValue"
    />
  </MField>
</template>

<style lang="scss" scoped>
@import 'settings-tools/_all-settings';

.s-language-selector {
  @include set-from-screen('m') {
    display: none;
  }
}

.l-language-selector {
  @media screen and (max-width: 680px){
    display: none;
  }
}
</style>
