<script setup>

</script>

<template>
  <div class="form-container">
    <div class="">
      <h1>
        <span>{{$t('dataPrivacyPolicy.title')}}</span>
      </h1>
      <h3>
        <span>{{$t('dataPrivacyPolicy.subtitle')}}</span>
      </h3>
      <h4>
        <span>{{$t('dataPrivacyPolicy.version')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.description')}}</span>
      </p>
    </div>
    <div class="">
      <h4>
        <span>{{$t('dataPrivacyPolicy.whoControl.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.whoControl.text')}}</span>
      </p>
    </div>
    <div>
      <h4>
        <span>{{$t('dataPrivacyPolicy.whyCollect.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.whyCollect.firstSpan')}}</span>
        <br/><br/>
        <span>{{$t('dataPrivacyPolicy.whyCollect.listTitle')}}</span>
      </p>
      <ul>
        <li><span>&nbsp{{$t('dataPrivacyPolicy.whyCollect.firstDash')}}</span></li>
        <li><span>&nbsp{{$t('dataPrivacyPolicy.whyCollect.secondDash')}}</span></li>
        <li><span>&nbsp{{$t('dataPrivacyPolicy.whyCollect.thirdDash')}}</span></li>
        <li><span>&nbsp{{$t('dataPrivacyPolicy.whyCollect.fourthDash')}}</span></li>
      </ul>
      <p>{{$t('dataPrivacyPolicy.whyCollect.secondSpan')}}</p>
      <table>
        <tr>
          <th colspan="2">{{$t('dataPrivacyPolicy.whyCollect.firstTable.title')}}</th>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.first')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.firstTable.firstLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.second')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.firstTable.secondLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.third')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.firstTable.thirdLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.fourth')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.firstTable.fourthLine')}}</td>
        </tr>
      </table>
      <br/><br/>
      <table>
        <tr>
          <th colspan="2">{{$t('dataPrivacyPolicy.whyCollect.secondTable.title')}}</th>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.first')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.secondTable.firstLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.second')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.secondTable.secondLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.third')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.secondTable.thirdLine')}}</td>
        </tr>
        <tr>
          <td>{{$t('dataPrivacyPolicy.whyCollect.tableLineTitles.fourth')}}</td>
          <td>{{$t('dataPrivacyPolicy.whyCollect.secondTable.fourthLine')}}</td>
        </tr>
      </table>
      <p>
        <span>{{$t('dataPrivacyPolicy.whyCollect.thirdSpan')}}</span>
      </p>
    </div>
    <div>
      <h4>
        <span>{{$t('dataPrivacyPolicy.howLong.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.howLong.text')}}</span>
      </p>
    </div>
    <div>
      <h4>
        <span>{{$t('dataPrivacyPolicy.howProtected.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.howProtected.firstSpan')}}</span>
        <br/>
        <br/>
        <span>{{$t('dataPrivacyPolicy.howProtected.secondSpan')}}</span>
      </p>
    </div>
    <div>
      <h4>{{$t('dataPrivacyPolicy.yourRights.title')}}</h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.yourRights.firstSpan')}}</span>
        <br/><br/>
        <span>{{$t('dataPrivacyPolicy.yourRights.secondSpan')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.thirdSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.thirdSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.fourthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.fourthSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.fifthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.fifthSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.sixthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.sixthSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.seventhSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.seventhSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.eighthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.eighthSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.ninthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.ninthSpan.text')}}</span>
        <br/><br/>
        <span class="rights-title">{{$t('dataPrivacyPolicy.yourRights.tenthSpan.title')}}</span><span>{{$t('dataPrivacyPolicy.yourRights.tenthSpan.text')}}</span>
        <br/><br/>
        <span>{{$t('dataPrivacyPolicy.yourRights.eleventhSpan')}}</span> <a href="https://privacyportal-eu.onetrust.com/webform/b1bfff13-ac00-4c6f-a1a3-06f2aaf34c5e/01a77099-217c-45ac-85f1-25951f4bc587" target="_blank">{{$t('dataPrivacyPolicy.yourRights.oneTrustLinkText')}}</a>
        <br/><br/>
        <span>{{$t('dataPrivacyPolicy.yourRights.twelfthSpan.firstParty')}}</span> <a :href="$t('dataPrivacyPolicy.yourRights.twelfthSpan.cnilLink')" target="_blank">{{$t('dataPrivacyPolicy.yourRights.twelfthSpan.cnilLinkText')}}</a> <span>{{$t('dataPrivacyPolicy.yourRights.twelfthSpan.secondParty')}}</span>
        <br/><br/>
      </p>
    </div>
    <div>
      <h4>
        <span>{{$t('dataPrivacyPolicy.howInformed.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.howInformed.text')}}</span>
      </p>
    </div>
    <div>
      <h4>
        <span>{{$t('dataPrivacyPolicy.howContact.title')}}</span>
      </h4>
      <p>
        <span>{{$t('dataPrivacyPolicy.howContact.text')}}</span>
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'settings-tools/_all-settings';

.form-container {
  margin: 0 auto;
  max-width: 1040px;
  text-align: justify !important;
  padding: 0 3%;
  @include set-from-screen('m') {
    padding: 0;
  }
  margin-bottom: 3%;
}

ul {
  list-style-type: none;
  text-align: left;
}

li::before {
  content: "– ";
}

th {
  background-color: #374566;
}

table, th, td {
  border: 2px solid white;
  border-collapse: collapse;
  padding: 1% 1% 2% 1%;
  text-align: left;
}

td {
  width: 50%;
  align-content: baseline;
}

td:first-child {
  font-weight: bold;
}

.rights-title {
  font-weight: bold;
}

a {
  color: white;
}
</style>