<script setup>
    import { computed, defineEmits } from 'vue';
    import { MLayer, MButton, MHeading, MDataTableV2, MDataTableColumn } from '@mozaic-ds/vue-3';
    import { getImagePath } from '@/utils/image';
    import { useRouter } from 'vue-router';
    import store from '@/store';
    
    const router = useRouter();
    const emits = defineEmits(['download-files']);
    
    let filesToDownload = [],
		selection = [];

    const documentNotFound = () => {
		store.commit('setProductDetails', productDetails.value);
        store.dispatch('displayLayerProductDetails', false);
    };
    
    const getProductImagePath = () => {
        return productDetails.value.photoUrl;
    };

    const closeLayer = () => {
		filesToDownload = [];
		selection = [];
		store.dispatch('displayLayerProductDetails', false);
    };
    
    const selectFile = (event) => {
        filesToDownload.push(event.data);
    };

    const unselectFile = (event) => {
        filesToDownload = filesToDownload.filter((file) => file.id !== event.data.id);
    };

    const selectAllFiles = (event) => {
        event.data.forEach((file) => {
            addFileToDownLoad(file);
        });
    };

    const unselectAllFiles = () => {
		filesToDownload = [];
    };

    const addFileToDownLoad = (file) => {
        filesToDownload.push(file);
    };

    const downloadFiles = () => {
        emits('download-files', filesToDownload);
    };
    
    const productDetails = computed(() => store.getters.productDetails);
    const productDetailsFiles = computed(() => store.getters.productDetailsFiles);

</script>

<template>
    <MLayer
            :open="store.getters.isOpenLayer"
            :closeOnOverlay="true"
            :layerTitle="$t('ProductDetailTitle')"
            @update:open="closeLayer"
            :extended="true"
    >
        <template v-slot:default>
            <section v-if="productDetails" class="product_details">
                <div class="product_details__header">
                    <div class="product_details__header-title">
                        <MHeading :underline="true" size="s">
                            <template v-slot:default>{{ productDetails.name }}</template>
                        </MHeading>
                    </div>
                    <div class="product_details__header-image">
                        <img :src="getProductImagePath()" :alt="productDetails.name" width="180px" />
                    </div>
                </div>
                <div class="product_details__infos">
                    <table>
                        <thead>
                        <tr>
                            <th>{{ $t('REFERENCE') }}</th>
                            <th>{{ $t('GTIN') }}</th>
                            <th>{{ $t('ADEOKEY') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>
                                <span v-for="(reference, index) in productDetails.references" :key="index">
                                    {{ reference }}<span v-if="index < productDetails.references.length - 1">, </span>
                                </span>
                            </th>
                            <th>
                                <span v-for="(gtin, index) in productDetails.gtins" :key="index">
                                    {{ gtin }}<span v-if="index < productDetails.gtins.length - 1">, </span>
                                </span>
                            </th>
                            <th>{{ productDetails.adeoKey }}</th>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div v-if="!!productDetailsFiles" class="product_details__files">
                    <h4 class="product_details__files-title">{{ $t('documents') }}</h4>
                    <MDataTableV2
                            :items="productDetailsFiles"
                            selectable
                            v-model:selection="selection"
                            @select-row="selectFile"
                            @unselect-row="unselectFile"
                            @select-all-rows="selectAllFiles"
                            @unselect-all-rows="unselectAllFiles"
                    >
                        <MDataTableColumn :label="$t('type')" value="documentTypeLabel"></MDataTableColumn>
                        <MDataTableColumn :label="$t('fileName')" value="fileNameToDisplay"></MDataTableColumn>
                        <MDataTableColumn :label="$t('extension')" value="fileExtension"></MDataTableColumn>
                    </MDataTableV2>
                    <div class="user_unfound_doc">
                        <MLink
                                v-bind="{ router: { name: 'router-link', props: { to: { name: 'missing' } } } }"
                                @click="documentNotFound"
                        >
                            {{ $t('missingDocumentPageLabel') }}
                        </MLink>
                    </div>
                    <div class="product_details__files-download-btn">
                        <MButton :label="$t('DownloadFiles')" @click="downloadFiles"></MButton>
                    </div>
                </div>
                <div v-else>
                    <p class="product_details__files-not-found">{{ $t('noFilesMessage') }}</p>
                    <div class="user_unfound_doc">
                        <MLink
                                v-bind="{ router: { name: 'router-link', props: { to: { name: 'missing' } } } }"
                                @click="documentNotFound"
                        >
                            {{ $t('missingDocumentPageLabel') }}
                        </MLink>
                    </div>
                </div>
            </section>
        </template>
    </MLayer>
</template>


<style lang="scss" scoped>
    @import 'settings-tools/_all-settings';
    .product_details {
		font-family: 'Roboto', sans-serif;
		&__header {
			display: flex;
			flex-wrap: nowrap;
			&-title {
				flex: 0 1 50%;
			}
			&-image {
				flex: 0 1 50%;
			}
		}
		&__infos {
			margin: $mu200 0;
			thead th {
				text-align: left;
				@include set-font-scale('02', 'm');
				color: $color-grey-999;
				padding-right: $mu200;
			}
			tbody th {
				@include set-font-scale('01', 'm');
				color: $color-grey-400;
				text-align: left;
				padding-right: $mu200;
			}
		}
		&__description {
			margin-bottom: $mu200;
			text-align: left;
			&-title {
				color: $color-grey-999;
				@include set-font-scale('05', 'm');
				font-weight: bold;
				margin-bottom: $mu050;
			}
			&-text {
				color: $color-grey-800;
				@include set-font-scale('03', 'm');
			}
		}
		&__files {
			&-download-btn {
			}
			&-not-found {
				color: $color-grey-800;
				@include set-font-scale('05', 'm');
			}
		}

		.user_unfound_doc{
			padding-top: 1em;
			padding-bottom: 1em;		
		}
    }
</style>