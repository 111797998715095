<template>
  <Header :sticky="true" :breadcrumbItems="breadcrumbItems" />
  <LegalNotices class="container" />
  <Footer />
</template>

<script>

import LegalNotices from "@/components/LegalNotices.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    LegalNotices,
    Footer
  },
  computed: {
    breadcrumbItems() {
      return [
        {text: this.$t('homePageLabel'), href: '/'},
        {text: this.$t('legalNotices.name'), href: null, active: true}
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  color: white;
}
</style>