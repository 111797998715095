import RequestUtils from '@/service/RequestUtils';

export const FileService = {
	async downloadFile(id) {
		const apiURL = process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 + '/api/documents/' + id + '/downloadDocument';

		return await RequestUtils.executeRequest(apiURL);
	},
	async downloadZipFile(ids) {
		const apiURL =
			process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1 + '/api/documents/downloadAsZip?documentIds=' + ids;

		return await RequestUtils.executeRequest(apiURL);
	},
};
