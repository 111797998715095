<template>
  <footer class="l-footer">
    <div class="l-footer__container">
      <div class="l-footer__copyright">
        &copy; {{ currentYear }} Adeo Services, {{ $t('allRightsReserved') }}
      </div>
      <div class="l-footer__adeo-link">
        <a href="https://www.adeo.com" target="_blank">https://www.adeo.com</a>
      </div>

      <div class="l-footer__links">
        <ul>
          <li>
            <MLink
                v-bind="{ router: { name: 'router-link', props: { to: { name: 'notices' } } } }"
            >
              {{ $t('legalNotices.name') }}
            </MLink>
          </li>
          <li>
            <MLink
                v-bind="{ router: { name: 'router-link', props: { to: { name: 'privacy' } } } }"
            >
              {{ $t('dataPrivacyPolicy.name') }}
            </MLink>
          </li>
          <li hidden="hidden"><a href="#">{{ $t('cookiesPolicy') }}</a></li>
          <li hidden="hidden"><a href="#">{{ $t('cookiesManagement')}}</a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup>
const currentYear = new Date().getFullYear();

</script>

<style lang="scss" scoped>
@import 'settings-tools/_all-settings';

.l-footer {
  background-color: white;
  padding: $mu050 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
  border-top: 1px solid #e0e0e0;
  font-size: 0.7rem;

  &__container {
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    padding: $mu050 $mu100;
    @include set-from-screen('m') {
      max-width: 1040px;
      display: flex;
    }
  }

  &__copyright {
    text-align: center;
    color: #333;
    @include set-from-screen('m') {
      text-align: left;
    }
  }

  &__adeo-link {
    margin-top: 3%;
    @include set-from-screen('m') {
      margin-top: 0;
    }
    a {
      color: #333;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__links {
    @include set-from-screen('m') {
      text-align: center;
      border-top: none;
      padding-top: 0;
      margin-top: 0;
    }

    border-top: 1px solid grey;
    padding-top: 2%;
    margin-top: 4%;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      @include set-from-screen('m') {
        display: flex;
      }
      
      li {
        margin: 0 $mu050;
        padding: $mu050;
        text-align: left;
        @include set-from-screen('m') {
          padding: 0;
        }
        a {
          color: #333;
          text-decoration: none;
          font-size: 0.7rem;
          line-height: 1;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      li:first-child, li:nth-child(3) {
        text-align: right;
      }
    }
  }
}
</style>
