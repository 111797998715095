<template>
  <div class="form-container">
    <div class="form">
      <MField
        class="form-field"
        :label="$t('missingDocForm.selectDocument.label')"
        id="document"
        :required="true"
        :requirementText="$t('mandatory')"
      >
        <MDropdown
          v-model="formData.document"
          :items="documentTypes"
          ariaLabelledBy="default"
          :isInvalid="formSubmitted && !formData.document"
          :placeholder="$t('missingDocForm.selectDocument.placeholder')"
        />
      </MField>

      <MField
        class="form-field"
        :label="$t('missingDocForm.profil.label')"
        id="profile"
        :required="true"
        :requirementText="$t('mandatory')"
      >
        <MDropdown
          v-model="formData.profile"
          :items="profiles"
          ariaLabelledBy="default"
          :isInvalid="formSubmitted && !formData.profile"
          :placeholder="$t('missingDocForm.profil.placeholder')"
        />
      </MField>

      <div class="form-row">
        <MField
          :label="$t('missingDocForm.name.label')"
          id="name"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.name" :isInvalid="formSubmitted && !formData.name" :placeholder="$t('missingDocForm.name.placeholder')" />
        </MField>
        <MField
          :label="$t('missingDocForm.firstName.label')"
          id="firstName"
          :required="true"
          :requirementText="$t('mandatory')"
          class="form-field"
        >
          <MTextInput v-model="formData.firstName" :isInvalid="formSubmitted && !formData.firstName" :placeholder="$t('missingDocForm.firstName.placeholder')" />
        </MField>
      </div>

      <MField
        :label="$t('missingDocForm.country.label')"
        id="country"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextInput v-model="formData.country" :isInvalid="formSubmitted && !formData.country" :placeholder="$t('missingDocForm.country.placeholder')" />
      </MField>

      <MField
        :label="$t('missingDocForm.email.label')"
        id="email"
        :required="true"
        :requirementText="$t('mandatory')"
        class="form-field"
      >
        <MTextInput v-model="formData.email" :isInvalid="formSubmitted && !formData.email" :placeholder="$t('missingDocForm.email.placeholder')" />
      </MField>

      <MCheckbox
        id="checkbox"
        v-model="formData.agree"
        :isInvalid="formSubmitted && !formData.agree"
      >
        <template #label class="checkbox-text">
          <p>{{ $t('missingDocForm.checkbox.description') }}</p>
          <MLink
              v-bind="{ router: { name: 'router-link', props: { to: { name: 'privacy' } } } }"
          >
            {{ $t('missingDocForm.checkbox.linkName') }}
          </MLink>
        </template>
      </MCheckbox>

      <MButton :label="$t('missingDocForm.submitButton')"  @click="handleSubmit" />
    </div>
    <MNotification v-if="formError" type="danger" class="notification">
          <p>
            {{ $t('failedFormSubmitNotificationMessage') }}
          </p>
    </MNotification>
  </div>
</template>

<script>
import { reactive, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { CustomerRequestService } from '@/service/CustomerRequestService';

export default {
  emits: ['form-submitted'],

  setup(props, {emit}) {
    const store = useStore();
    const product = computed(() => store.getters.productDetails || {});

    const formData = reactive({
      document: null,
      profile: null,
      name: '',
      firstName: '',
      country: '',
      email: '',
      agree: false,
    });

    const formSubmitted = ref(false);
    const formError = ref(false);

    async function handleSubmit() {
      formSubmitted.value = true;
      if (
          formData.document &&
          formData.profile &&
          formData.name &&
          formData.firstName &&
          formData.country &&
          formData.email &&
          formData.agree
      ) {
        try {
          const success = await CustomerRequestService.missingDocumentRequest(formData, product.value);

          if (success) {
            formError.value = false;
            emit('form-submitted');
          } else {
            formError.value = true;
            throw new Error("Failed to submit form");
          }
        } catch (error) {
          formError.value = true;
        }
      }
    };

    return {
      product,
      formError,
      formSubmitted,
      formData,
      handleSubmit
    }
  },

  computed: {
    documentTypes() {
      return [
        { id: 1, value: 'DOC', label: this.$t('documentTypeLabel.DOC') },
        { id: 2, value: 'EPS', label: this.$t('documentTypeLabel.EPS') },
        { id: 3, value: 'IMD', label: this.$t('documentTypeLabel.IMD') },
        { id: 4, value: 'DOP', label: this.$t('documentTypeLabel.DOP') },
        { id: 5, value: 'ERP', label: this.$t('documentTypeLabel.ERP') },
        { id: 6, value: 'PAC', label: this.$t('documentTypeLabel.PAC') },
        { id: 7, value: 'PLB', label: this.$t('documentTypeLabel.PLB') },
        { id: 8, value: 'FDS', label: this.$t('documentTypeLabel.FDS') },
        { id: 9, value: 'FIRE', label: this.$t('documentTypeLabel.FIRE') },
        { id: 10, value: 'RIM', label: this.$t('documentTypeLabel.RIM') },
        { id: 11, value: 'SVHC', label: this.$t('documentTypeLabel.SVHC') }
      ]
    },
    profiles() {
      return [
        { id: 1, value: 'PROFESSIONAL', label: this.$t('missingDocForm.profil.value.professional') },
        { id: 2, value: 'INDIVIDUAL', label: this.$t('missingDocForm.profil.value.individual') }
      ]
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: #ffffff;
  margin: 50px auto;
  border-radius: 10px;
  max-width: 1008px;
}

.form {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
}

.form-field {
  display: flex;
  flex-direction: column;
  text-align: left !important;
}

@media (max-width: 768px) {
  .form-container {
    width: 90%;
    padding: 20px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 769px) {
  .form-container {
    padding: 40px 60px;
  }
}

.mc-checkbox__label p {
  font-weight: bold!important;
}
.mc-checkbox__label a {
  font-weight: bold!important;
}
.notification {
  margin-top: 20px;
}
</style>
