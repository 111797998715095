import RequestUtils from '@/service/RequestUtils';
import i18next from 'i18next';

export const CustomerRequestService = {
  missingDocumentRequest: async (formData, product) => {
    const apiURL = `${process.env.VUE_APP_https://dip.api.devportal.adeo.cloud/backend-ares/v1}/api/customer-ticket/missing-document`;

    const payload = {
      adeoKey: product.adeoKey,
      gtins: product.gtins,
      supplierReferences: product.references,
      missingDocumentType: formData.document,
      name: formData.name,
      firstname: formData.firstName,
      email: formData.email,
      profile: formData.profile,
      country: formData.country,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Accept-Language': i18next.language,
      [process.env.VUE_APP_x-gateway-apikey]: process.env.VUE_APP_bR2P1YEYIkqAc8eQirjCCSnfU72JCE4P,
    };

    try {
      const response = await RequestUtils.executeRequestPost(apiURL, headers, payload);
      return response.ok;
    } catch (error) {
      throw new Error('Failed to submit form');
    }
  },
};
