<script setup>
	import { MTextInput, MField } from '@mozaic-ds/vue-3';
	import store from '@/store';
	let searchKeyWord = '';
	const sendSearchString = () => {
		if (searchKeyWord.length > 0) {
			store.dispatch('searchProducts', { keyWord: searchKeyWord });
		}
	};
</script>
<template>
	<div class="l-searchBar">
		<div class="l-searchBar__container">
			<h1>{{ $t('title') }}</h1>
			<p class="l-searchBar__description">{{ $t('description') }}</p>
			<MField
				id="search-bar"
				:label="$t('SearchInvite')"
				required="true"
				:requirementText="$t('mandatory')"
			>
				<MTextInput
					@keyup.enter="sendSearchString"
					id="defaultField"
					icon="DisplaySearch48"
					:placeholder="$t('SearchPlaceholder')"
					v-model="searchKeyWord"
				/>
			</MField>
		</div>
	</div>
</template>
<style lang="scss" scoped>
	@import 'settings-tools/_all-settings';
	.l-searchBar {
		font-family: 'Roboto', sans-serif;
		max-width: 1040px;
		margin: 0 auto;
		&__container {
			margin: 0 $mu100;
			background-color: white;
			padding: $mu100;
			border-radius: 8px;
			color: $color-grey-900;
			@include set-from-screen('m') {
				padding: $mu250 $mu400;
				max-width: fit-content;
				margin-inline: auto;
				margin: 0 $mu100;
			}
		}

		h1 {
			@include set-font-scale('09', 'm');
			margin-bottom: $mu100;
			@include set-from-screen('m') {
				margin-bottom: $mu250;
			}
		}
		&__description {
			@include set-font-scale('06', 'm');
			margin-bottom: $mu100;
			text-align: left;
			@include set-from-screen('m') {
				margin-bottom: $mu250;
			}
		}
		.mc-field {
			text-align: left;
		}
	}
</style>
